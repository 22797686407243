import { lazy } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { remove } from "store/actions/alert";
import { useAppDispatch } from "hooks";

const Login = lazy(() => import("pages/Auth/Login"));
const FirstTimeResetPassword = lazy(
  () => import("pages/Auth/FirstTimeResetPassword")
);
const BusinessEdit = lazy(() => import("pages/Business/Edit"));
const BusinessList = lazy(() => import("pages/Business/List"));
const BusinessUsers = lazy(() => import("pages/Business/Users"));
const Register = lazy(() => import("pages/Business/Add/Register"));
const AddProject = lazy(() => import("pages/Project/Add/AddProject"));
const NotFound = lazy(() => import("pages/Error/NotFound"));
const ProjectList = lazy(() => import("pages/Project/List"));
const ProjectEdit = lazy(() => import("pages/Project/Edit/Index"));
const Schedule = lazy(() => import("pages/Project/Schedules/Schedule"));
const ListTestingRequest = lazy(
  () => import("pages/Project/TestingRequest/List")
);
const TestingDetail = lazy(() => import("pages/Project/TestingRequest/Detail"));
const TestingGroup = lazy(() => import("pages/TestScope/TestGroup/TestGroups"));
const SampleMaterialList = lazy(() => import("pages/Material/List"));
const NotifyList = lazy(() => import("pages/Notification/NotifyList"));
const NotificationDetail = lazy(
  () => import("pages/Notification/NotifyDetail")
);
const EmailTemplateList = lazy(() => import("pages/EmailTemplate/List"));
const Tracking = lazy(() => import("pages/Tracking/TrackingPage"));
const DetailSample = lazy(
  () => import("pages/Tracking/TrackingDetail/DetailSample")
);
const ComingSoon = lazy(() => import("pages/OtherPage/ComingSoon"));
const Page403 = lazy(() => import("pages/OtherPage/Page403"));
const EnquiryList = lazy(() => import("pages/Enquiry/List"));
const EnquiryDetail = lazy(() => import("pages/Enquiry/Detail"));
const UploadReport = lazy(
  () => import("pages/Project/TestingRequest/UploadReport")
);
const UploadReportTrackingPage = lazy(
  () => import("pages/Project/TestingRequest/UploadReport")
);
const UserSettingList = lazy(() => import("pages/UserSetting/List"));
const UserSettingDetail = lazy(() => import("pages/UserSetting/Detail"));
const UserEdit = lazy(() => import("pages/Business/UserEdit"));
const TestEntrySheet = lazy(
  () => import("pages/Tracking/TrackingDetail/TestEntrySheet")
);
const ReportVersionHistoryList =  lazy(
  () => import("pages/Tracking/TrackingDetail/ReportVersionHistoryList")
);
const ReportHistoryDetail = lazy(
  () => import("pages/Tracking/TrackingDetail/ReportHistoryDetail")
);
const DataUnavailable = lazy(() => import("pages/OtherPage/DataUnavailable"));

// Defining a type for routeItem object
type routeItem = {
  path: string; // Path of the route
  name: string; // Name of the route
  title: string; // Title of the route (for display purposes)
  key: string; // Unique key for the route
  exact: boolean; // Boolean flag indicating if path should match exactly or partially
  component: Function; // Component to be rendered when the route is accessed
  requiredAuth: boolean; // Boolean flag indicating if authentication is required to access the route
  roles: Array<string>; // Array of user roles that are allowed to access this route
};

// Defining a type for routes object, which extends routeItem
// and has an optional array of nested routes
type routes = routeItem & {
  routes?: routeItem[]; // Optional nested routes
};

const ROUTES: routes[] = [
  {
    path: "/", // Root path for the application
    name: "homepage", // Unique name for the route
    title: "Admin Dashboard", // Title of the route (for display purposes)
    key: "homepage", // Unique key for the route
    exact: true, // Boolean flag indicating if path should match exactly or partially
    component: ComingSoon, // Component to be rendered when the route is accessed
    routes: [], // Array of nested routes (if any)
    requiredAuth: true, // Boolean flag indicating if authentication is required to access the route
    roles: ["admin", "geotechnics"], // Array of user roles that are allowed to access this route
  },
  {
    path: "/login", // Path for the login route
    name: "login", // Unique name for the route
    title: "Login", // Title of the route (for display purposes)
    key: "login", // Unique key for the route
    exact: true, // Boolean flag indicating if path should match exactly or partially
    component: Login, // Component to be rendered when the route is accessed
    routes: [], // Array of nested routes (if any)
    requiredAuth: false, // Boolean flag indicating if authentication is required to access the route
    roles: ["admin", "geotechnics"], // Array of user roles that are allowed to access this route
  },
  {
    path: "/first-time-reset-password", // Path for the first time password reset route
    key: "ROOT", // Unique key for the route
    exact: true, // Boolean flag indicating if path should match exactly or partially
    component: FirstTimeResetPassword, // Component to be rendered when the route is accessed
    routes: [], // Array of nested routes (if any)
    requiredAuth: false, // Boolean flag indicating if authentication is required to access the route
    name: "first-time-reset-password", // Name of the route
    title: "First Time Reset Password", // Title of the route (for display purposes)
    roles: ["admin", "geotechnics"], // Array of user roles that are allowed to access this route
  },
  // Dashboard Route
  {
    path: "/dashboard",
    name: "dashboard", // identifier for this route
    title: "Dashboard", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ComingSoon, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Business Add Route
  {
    path: "/business/add",
    name: "business-add", // identifier for this route
    title: "Generate Business Admin Account", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: Register, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Business Edit Route
  {
    path: "/business/:id/edit",
    name: "business-edit", // identifier for this route
    title: "Update Business Information", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: BusinessEdit, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for listing users of a specific business.
  {
    path: "/business/:id/users", // URL path with wildcard parameter for business ID
    name: "business-users", // identifier for this route
    title: "List Users of Business", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: BusinessUsers, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for managing businesses.
  {
    path: "/businesses", // URL path
    name: "business-list", // identifier for this route
    title: "Business Management", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: BusinessList, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for editing user information.
  {
    path: "/business/:businessId/users/:userId", // URL path with wildcard parameters for business ID and user ID
    name: "user-edit", // identifier for this route
    title: "Update User Information", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load this component
    component: UserEdit, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for account information.
  {
    path: "/account", // URL path
    name: "account", // identifier for this route
    title: "account", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ComingSoon, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for notifications.
  {
    path: "/notifications", // URL path
    name: "notifications", // identifier for this route
    title: "Notifications", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: NotifyList, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for managing projects.
  {
    path: "/projects", // URL path
    name: "project-list", // identifier for this route
    title: "Project Management", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ProjectList, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for managing enquiries.
  {
    path: "/enquiries", // URL path
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: EnquiryList, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
    name: "enquiries", // identifier for this route
    title: "Enquiries", // title that will be displayed in the header
  },

  // Route for viewing enquiry details.
  {
    path: "/enquiry/:id", // URL path with wildcard parameter for enquiry ID
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load this component
    component: EnquiryDetail, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: false, // authentication not required to view enquiry details
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
    name: "enquiry", // identifier for this route
    title: "Enquiry Detail", // title that will be displayed in the header
  },

  // Route for settings.
  {
    path: "/settings", // URL path
    name: "settings", // identifier for this route
    title: "settings", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ComingSoon, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin"], // array of user roles who can access this route
  },

  // Route for frequently asked questions.
  {
    path: "/faqs", // URL path
    name: "faqs", // identifier for this route
    title: "faqs", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ComingSoon, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin"], // array of user roles who can access this route
  },

  // Route for adding a new project.
  {
    path: "/project/add", // URL path
    title: "Project Request Form", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: AddProject, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
    name: "project/add", // identifier for this route
  },

  // Route for editing project information.
  {
    path: "/projects/:id/edit", // URL path with wildcard parameter for project ID
    name: "projects-edit", // identifier for this route
    title: "Update Project Information", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ProjectEdit, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for managing testing scopes.
  {
    path: "/testings", // URL path
    name: "testings", // identifier for this route
    title: "Testing Scopes Management", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: TestingGroup, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin"], // array of user roles who can access this route
  },

  // Route for managing sample materials.
  {
    path: "/materials", // URL path
    name: "sample-material-list", // identifier for this route
    title: "Sample Material Management", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: SampleMaterialList, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin"], // array of user roles who can access this route
  },

  // Route for creating a new testing request.
  {
    path: "/projects/:id/create-testing-request", // URL path with wildcard parameter for project ID
    name: "create-testing-request", // identifier for this route
    title: "Create Testing Request", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: Schedule, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for listing all testing requests.
  {
    path: "/projects/:id/testing-requests", // URL path with wildcard parameter for project ID
    name: "list-testing-request", // identifier for this route
    title: "Testing request", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ListTestingRequest, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for viewing a specific testing request.
  {
    path: "/projects/:id/testing-request/:request_id", // URL path with wildcard parameters for project ID and request ID
    name: "testing-request", // identifier for this route
    title: "Testing Request Detail", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: TestingDetail, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for uploading a test report.
  {
    path: "/projects/:id/testing-request/:request_id/upload", // URL path with wildcard parameters for project ID and request ID
    name: "testing-report-upload", // identifier for this route
    title: "Testing Report Upload", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: UploadReport, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for editing a notification
  {
    path: "/notifications/:id/edit", // URL path with wildcard parameter for notification ID
    name: "notification-detail", // identifier for this route
    title: "Detail Notification", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: NotificationDetail, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for managing email templates.
  {
    path: "/email-templates", // URL path
    name: "email-template-list", // identifier for this route
    title: "Email Template Management", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: EmailTemplateList, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin"], // array of user roles who can access this route
  },

  // Route for tracking page.
  {
    path: "/tracking", // URL path
    name: "tracking", // identifier for this route
    title: "Tracking Page", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: Tracking, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for viewing detail sample.
  {
    path: "/tracking/:id", // URL path with wildcard parameter for sample ID
    name: "detail-sample", // identifier for this route
    title: "Detail Sample", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: DetailSample, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for managing test entry sheet.
  {
    path: "/tracking/:sample_unique_id/:testing_type_id", // URL path with wildcard parameters for sample unique ID and testing type ID
    name: "test-entry-sheet", // identifier for this route
    title: "Test Entry Sheet", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: TestEntrySheet, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for uploading a test report.
  {
    path: "/tracking/:sample_unique_id/:testing_type_id/upload", // URL path with wildcard parameters for project ID and request ID
    name: "speciment-report-upload", // identifier for this route
    title: "Version History", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: UploadReportTrackingPage, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for viewing test report versions, given the report ID
  {
    path: "/version-history/:report_id/",   // URL path with wildcard parameters for sample unique ID and testing type ID
    name: "version-history-list", // identifier for this route
    title: "Version History List", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ReportVersionHistoryList, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: false, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for viewing test report history, given its ID.
  {
    path: "/version-history/:report_id/:version_history_id", // URL path with wildcard parameters for sample unique ID and testing type ID
    name: "version-history-detail", // identifier for this route
    title: "Version History Detail", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ReportHistoryDetail, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for data page (coming soon)
  {
    path: "/data", // URL path
    name: "data", // identifier for this route
    title: "Data", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: ComingSoon, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for locking the screen
  {
    path: "/lock", // URL path
    name: "lock", // identifier for this route
    title: "Lock", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: Page403, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for displaying "permission denied" message
  {
    path: "/403", // URL path
    name: "permission-denined", // identifier for this route
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: Page403, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    title: "Page403", // title that will be displayed in the header
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },

  // Route for managing Geotechnics user settings
  {
    path: "/users", // URL path
    name: "user-setting-list", // identifier for this route
    title: "Geotechnics Users Management", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: UserSettingList, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin"], // array of user roles who can access this route
  },

  // Route for viewing user information
  {
    path: "/users/:id", // URL path with wildcard parameter for user ID
    name: "user-setting-detail", // identifier for this route
    title: "User Information", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: UserSettingDetail, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin"], // array of user roles who can access this route
  },
  {
    path: "/data-unavailable", // URL path with wildcard parameter for user ID
    name: "data-unavailable", // identifier for this route
    title: "Data unavailable", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: DataUnavailable, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
    roles: ["admin", "geotechnics"], // array of user roles who can access this route
  },
];
// Exporting an object that contains all the routes for the application
export default ROUTES;

// Exporting a function called RenderRoutes that accepts an object with an array of routes as a parameter
export function RenderRoutes({ routes }: { routes: routes[] }) {
  return (
    // Using a switch statement to render only one route at a time
    <Switch>
      {/* Using the Array.map method to map over each route and create a new RouteWithSubRoutes component for each one */}
      {routes.map((route, i) => {
        return <RouteWithSubRoutes {...route} />;
      })}

      {/* Adding a wildcard (*) route at the end that will be rendered if none of the other routes match */}
      <Route component={() => <NotFound />} />
    </Switch>
  );
}

/**
 * Renders a route with its sub-routes.
 * @param route - The route object to render.
 * @returns A component that renders the provided route with sub-routes.
 */
function RouteWithSubRoutes(route: routes) {
  // Get the dispatch function for updating Redux store
  const dispatch = useAppDispatch();

  // Get the history object for manipulating browser history
  const history = useHistory();

  // Set the document title to the current route title
  document.title = route.title;

  // Get the user object from local storage or set it to an empty object if not found
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  // Render the provided route with sub-routes
  return (
    <Route
      key={route.key}
      path={route.path}
      exact={route.exact}
      name={route.name}
      render={(props) => {
        // Remove any existing error message from the store
        dispatch(remove());

        // Set the current route name in local storage
        localStorage.setItem("currentRoute", route.name);

        // Check if user is authenticated and redirect to login page if required
        if (!localStorage.getItem("token")) {
          if (route.requiredAuth) {
            window.location.href = "/login?continue=" + encodeURIComponent(window.location.href);
          }
        } else {
          // Redirect to dashboard if user is already logged in
          ["/login", "/forgot-password"].includes(route.path) &&
            history.push("/dashboard");

          // Redirect to 403 page if user doesn't have required role for the current route
          if (!route.roles.includes(user.role)) {
            window.location.href = "/403";
          }
        }

        // Render the component associated with the current route, along with its sub-routes
        return (
          <route.component
            {...props}
            routes={route.routes}
            routeName={route.name}
          />
        );
      }}
    />
  );
}
